.sectionBackground {
  background-color: #f9f9f9;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 60px 0 200px 0;
}
.titleContainer {
  display: flex;
  align-items: flex-end; // This will align the bottoms of the elements.
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  // padding-bottom: 100px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  .iconWithText {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .umbrellaIcon {
      position: absolute;
      bottom: 100%; // Places the icon right above the text.
    }

    h1 {
      margin: 0; // Removes default margin of the h1.
    }
  }

  .ebitda {
    background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
    -webkit-background-clip: text;
    color: transparent; // This will make the gradient visible as text color.
    margin: 0 5px; // Adds a bit of spacing between the words.
  }
}
.navigationButton {
  &:hover {
    // styles when the icon is hovered (e.g., scale, color change, etc.)
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
}
.sectionTitle {
  margin-bottom: 2rem; // Add space between the title and the content below.
}

.contentContainer {
  display: flex;
  align-items: center;
  width: 68%;
  justify-content: space-between;
}

.arrowsContainer {
  display: flex;
  flex-direction: column;
  margin-right: 2rem; // Space between arrows and cards.
}
.sectionTitle {
  margin-bottom: 2rem;
}

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .card:nth-child(1) .cardButton {
    background: none;
    padding: 0;
  }
  .card:nth-child(2) .cardButton {
    background: none;
    padding: 0;
  }
  .card:nth-child(3) .imageWithButton {
    justify-content: flex-end;
    .cardImage {
      display: none;
    }
    .cardButton {
      background-color: #ff4a1c;
      padding: 8px 16px;
      border-radius: 16px;
      display: flex;
      gap: 5px;
      align-items: center;
      color: white;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.card {
  // width: 351px;
  // height: 337px;
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // margin-right: -81px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;

  &.transitioning {
    margin-right: -300px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 1;
  }
}

.cardTitle {
  text-align: left;
  color: #fff;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 36.8px */
  letter-spacing: 0.035px;
  width: 80%;
}

.imageWithButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.cardImage {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 8px;
}
@keyframes zoomBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.cardButton {
  background-color: #d43e17;
  color: #fff;
  padding: 16px 12px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
  }
}

.navigationButton {
  position: relative;
  margin: 0 10px;
  margin-bottom: 50px;
  border: 3px solid #052247;
  border-radius: 8px;
  padding: 10px 25px;
  color: #052247;
  transition: background-color 0.3s ease;

  &:hover,
  &:active {
    background-color: #052247; /* Slightly lighter shade of orange */
    color: white; /* Black text */
    border-color: #052247;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.transparent {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);

    i {
      color: #052247;
    }
  }
}

@media (max-width: 1380px) {
  .sectionBackground {
    // height: 100vh;
  }
  .navigationButton {
    padding: 16px 12px;
    margin: 0 0;
    margin-bottom: 24px;
  }
  .card {
    width: 280px;
    height: 260px;
  }
  .cardTitle {
    font-size: 20px;
    width: 70%;
  }
  .cardButton {
    font-size: 15px;
  }
  .contentContainer {
    flex-direction: column-reverse;
    width: 100%;
  }
  .arrowsContainer {
    transform: rotate(90deg);
  }
  .cardImage {
    width: 60px;
    height: 60px;
  }
}

// Mobile devices
@media (max-width: 768px) {
  .sectionBackground {
    // flex-direction: column;
    height: 100%;
    padding: 38px 0;
    justify-content: space-between;
  }
  .cardsContainer {
    flex-direction: column;
  }
  .card:nth-child(1) {
    border-radius: 20px 20px 0 0;
  }
  .card:nth-child(2) {
    border-radius: 0px 0px 0 0;
  }
  .card:nth-child(3) {
    border-radius: 0px 0px 20px 20px;
  }
  .card {
    width: 354px;
    height: 200px;
    margin-right: 0; // Remove horizontal overlap

    transition: margin-top 0.5s ease;

    &.transitioning {
      margin-right: unset;
      margin-top: -120px;
    }
  }
  .card:last-child {
    margin-bottom: 0;
  }
  .cardTitle {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 30.4px */
    letter-spacing: 0.029px;
    width: 100%;
  }
  .cardButton {
    padding: 8px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.08px;
  }
  .navigationButton {
    padding: 16px 12px;
    margin: 0 0;
    margin-bottom: 24px;
  }
  .cardImage {
    width: 40px;
    height: 40px;
  }
  .arrowsContainer {
    margin: 0px;
    gap: 10px;
  }
  .imageWithButton {
    justify-content: space-between;
  }
  .titleContainer {
    font-size: 3rem;
    padding-bottom: 40px;
  }
}
@media (max-width: 425px) {
  .cardsContainer {
    width: 86%;
  }
  .card {
    width: 100%;
    min-width: 200px;
  }
  .sectionBackground {
    padding: 45px 0 100px 0;
  }
}
@media (max-width: 480px) {
  .titleContainer {
    font-size: 2.4rem;
    width: 80%;
  }
}
@media (max-width: 600px) {
  .titleContainer {
    font-size: 2.4rem;
  }
}
@media (max-width: 321px) {
  .titleContainer {
    font-size: 1.8rem;
    padding-left: 30px;
    padding-right: 30px;
  }
}

//New STYLES

@media (min-width: 2000px) {
  .contentContainer {
    max-width: 1400px;
    width: 60%;
  }
}
