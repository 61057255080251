.parentContainer {
  position: relative;
  height: 160px;
  background: linear-gradient(93deg, #9055a2 0%, #8290a3 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../../assets/knowGood/knowGoodSec1bg.webp");
  background-size: cover;
  background-position: top;
}
.parentContainer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(93deg, #9055a2 0%, #8290a3 100%);
  mix-blend-mode: multiply;
}

.secondContainer {
  width: 76%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.thinLine {
  width: 2px;
  height: 60px;
  background-color: white;
  z-index: 2;
}
.knowGood {
  color: white;
  font-family: Source Sans Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  z-index: 2;
}
.areYou {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 44.8px */
  letter-spacing: 0.14px;
  text-transform: capitalize;
  z-index: 2;
}
@media (max-width:1300px) {
  .secondContainer{
    width: 86%;
  }
}
@media (max-width:500px) {
  .knowGood{
    font-size: 21px;
    text-align: start;
  }
  .areYou{
    font-size: 16px;
    text-align: start;

  }
  .parentContainer{
    height: 100px;
  }
}

@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}