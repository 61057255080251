.parentContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    
    // padding: 32px 0 26px 0;
    position: fixed;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.78);
    right: 0;
    transition: transform 0.5s ease-in-out; // Move this line here
    transform: translateY(0%); // This stays here

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 6px;
    }
}
.hiddenHeader {
    transform: translateY(-100%); // This replaces the top: -100%;
}
.credvestLogoCon{
    &:active, &:focus{
    outline: none !important;
      box-shadow: none !important;
      -webkit-tap-highlight-color: transparent !important;
      }
      
}
.button{
    background-color: #FF4A1C;
    padding: 8px 16px;
    border-radius: 16px;
    display: flex;
}
.headerContainer{
    width: 76%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

}

.absolutePosition {
    // position: absolute;
    // top: 0; 
    // left: 0;
    // z-index: 999;
}

.relativePosition {
    position: relative;
}

  .popupBgColor{
    background-color: white;
    backdrop-filter: blur(2px);
    border: 20px solid rgba(255, 255, 255, 0);
    height: 100vh;

}

.flexOnLarge {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.hiddenOnLarge {
    display: none;
}


    @media (max-width:1000px) {
        .headerContainer{
            width: 76%;
        }
        .hiddenOnLarge {
            display: flex;
        }
        .flexOnLarge {
            display: none;
        }
        .button{
            display: none;
        }
        
    }

    @media (max-width:1300px) {
        .headerContainer{
            width: 86%;
        }
    }
@media (max-width:320px) {


}

.activeLink {
    color: #FF4A1C !important;
}
@media (min-width: 2000px) {
    .headerContainer{
        max-width: 1400px;
        width: 60%;
    }
}