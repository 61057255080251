.homeSection {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
  background: linear-gradient(90deg, rgba(5, 34, 71, 0.10) 0%, rgba(144, 85, 162, 0.10) 83.04%, rgba(255, 74, 28, 0.10) 100%);
}
.formContainer {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pageTitle {
  color: #071013;
  font-family: Oxygen;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 39.6px */
  letter-spacing: 0.083px;
  margin-bottom: 2%;
  text-align: left;
}
.emailNameContainer{
    display: flex;
}
.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.suggestionText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2.3%;
    margin-top: 1%;
}
.firstSuggestion {
    color: #071013;
    font-family: Source Sans Pro;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 15.95px */
    letter-spacing: 0.055px;
}
.secondSuggestion {
    color: #071013;
    font-family: Source Sans Pro;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 15.95px */
    letter-spacing: 0.055px;
}
.referSelect {
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    
    border-radius: 16px;
    background: #FBFFFE url('../../../assets//knowGood/icons/RightArrowStick.svg') no-repeat right 10px center;
    background-size: 20px 20px;  /* Adjust the width and height of the SVG */

    box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.12), 0px 4px 18px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    width: 100%;
    padding: 16px;
    padding-right: 30px; 
    align-items: center;
    gap: 8px;
}
.selectWrapper {
    position: relative;
    display: inline-block;
    width: 30%;
  }
  .selectIcon {
    position: absolute;
    right: 10px; /* adjust as necessary */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* to prevent icon from blocking the click event on select */
  }
  
  
.partnerField{
    display: flex;
width: 65%;
height: 54px;
padding: 16px;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 16px;
background: #FBFFFE;
box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.12), 0px 4px 18px 0px rgba(0, 0, 0, 0.08);
}
.contactTitle{
    color: #071013;
font-family: Oxygen;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 25.2px */
margin-top: 4%;
    margin-bottom: 4%;
}
.contactFieldContainer{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.phone, .email , .name{
    border-radius: 16px;
background: #FBFFFE;
box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.12), 0px 4px 18px 0px rgba(0, 0, 0, 0.08);
display: flex;
width: 30%;
padding: 16px;
align-items: center;
gap: 8px;

}
.lastRowContainer{
    display: flex;
    align-items: center;
    margin-top: 6%;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    gap: 3%;
    margin-bottom: 2%;
}
.congratsText{
    color: #071013;
font-family: Source Sans Pro;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 30.45px */
letter-spacing: 0.032px;
}
@media screen and (max-width:1400px) {
    .formContainer{
        width: 70%;
    }
}
@media screen and (max-width:900px) {
    .formContainer{
        width: 80%;
    }
    .pageTitle{
        font-size: 26;
    }
    .congratsText{
        font-size: 20px;
        margin-bottom: 2%;
    }
    .lastRowContainer{
        flex-direction: column;
        align-items: flex-end;
        gap: 2%;
    }
}
@media screen and (max-width:600px) {
    .selectWrapper{
        width: 100%;
        margin-bottom: 6%;

    }
    .pageTitle{
        font-size: 1.4rem;
        margin-bottom: 20px;
    }
    .firstRow{
        flex-direction: column;
    }
    .formContainer{
        width: 90%;
    }
    .referSelect{
        width: 100%;
    }
    .partnerField{
        width: 100%;
        margin-bottom: 3%;
    }
    .contactFieldContainer{
        flex-direction: column;
    }
    .phone, .name, .email{
        width: 100%;
        margin-bottom: 6%;
    }
    .congratsText{
        text-align: left;
        font-size: 17px;
        margin-bottom: 6%;
    }
    .homeSection{
        padding: 20% 0;
    }
}


.popupOverlay {
    position: fixed; /* Fixed/sticky position */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000; /* Positioned above everything else */
    background-color: rgba(0,0,0,0.5); /* Black with opacity */
    display: flex; 
    align-items: center; /* Centered vertically */
    justify-content: center; /* Centered horizontally */
}

.popupContent {
    background-color: #ffffff; /* White background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0,0,0,0.3); /* A little shadow for depth */
    max-width: 400px; /* Maximum width */
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.thankyouText{
    color: #071013;
    font-size: 2rem;
}

.popupButton {
    margin-top: 20px;
}
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    z-index: 1001;  /* Ensuring it's above the popup */
}
.form{
    width: 100%;
}

@media (min-width: 2000px) {
    .formContainer{
      max-width: 1000px;
      width: 40%;
    }
  }