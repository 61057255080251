.parentContainer {
  height: auto;
  padding: 10% 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-image: url("../../../assets/Marketing/MarketingSec3leftBg.svg"),
    url("../../../assets/Marketing/MarketingSec3rightBg.svg");
  background-position: -10vw center, 88vw 0px;
  background-repeat: no-repeat, no-repeat;
  background-size: 800px, 800px;
}
.tabs {
  display: flex;
  padding-bottom: 8%;
  gap: 60px;
  button {
    display: flex;
    padding: 16px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    font-family: Source Sans Pro;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%; /* 33.35px */
    letter-spacing: 0.115px;
  }
  .activeTopTab {
    background-color: #ff4a1c;
    color: white;

    // If you want to add some hover effect or transition for smooth color change
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: darken(
        #ff4a1c,
        10%
      ); // This is optional; darkens the color slightly on hover
    }
  }
}
.leftPane button {
  display: flex;
  width: 76%;
  height: 77px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: linear-gradient(90deg, #fff 0%, #fff 100%);
  margin-bottom: 2%;
  justify-content: space-between;
}
.iconWithTitle {
  display: flex;
  align-items: center;
  text-align: left;
}
.content {
  text-align: left;
  margin-bottom: 5%;
  padding-left: 2%;
  padding-right: 25%;
}
.secondContainer {
  width: 76%;
  display: flex;
}
.textHeader {
  color: #071013;
  font-family: Oxygen;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  margin-bottom: 3%;
}
.textBody {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
}
.button{
  &:active, &:focus{
    outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  }
}
.leftPane {
  width: 50%;
}
.rightPane {
  width: 50%;
  text-align: left;
}
@media (max-width:1300px) {
  .secondContainer{
    width: 86%;
  }
  
}

@media (max-width:1021px) {
  .leftPane, .rightPane{
    width: 50%;
  }
  .leftPane .button{
    width: 80%;
  }
}

@media (max-width:913px) {
  .secondContainer{
    flex-direction: column-reverse;
    align-items: center;
    gap: 60px;

  }
  .leftPane, .rightPane{
    width: 100%;
  }
  .leftPane button{
    width: 100%;
  }
}
@media (max-width:772px) {
  .tabs{
    gap: 0;
  }
  .rightPane{
   text-align: center;
  }
}
@media (max-width:600px) {
  .tabs button{
    font-size: 1rem;
    padding: 20px;
  }
}
/* Section3.module.scss */
.button {
  /* Other button styles here */
  outline: none; /* Remove the default focus outline */
}
@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}