.parentContainer{
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 800px;
overflow: hidden;
background-color: #F9F9F9;


background-image: url('../../../assets/culture/section5/CultureSec5LeftCircleBg.svg'), url('../../../assets/culture/section5/CultureSec5RightCircleBg.svg');
background-position: -22vw 350px, 85vw -221px;
    background-repeat: no-repeat, no-repeat;
    background-size: 500px, 800px;

}

.secondContainer{
width: 76%;
display: flex;
align-items: flex-end;
flex-direction: column;
justify-content: center;
z-index: 5;
}
.thirdContainer{
    width: 50%;
}
.text{
    color: #102E4A;
    font-family: Oxygen;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 36px */
    letter-spacing: -0.15px;
    text-align: left;
    padding-bottom: 2%;
}
.buttonIcon{
width: 40px;
}
.imageContainer{
    position: absolute;
    width: 500px;
    left: 100px;
}
@media (max-width:1300px) {
    .secondContainer{
        width: 86%;
    }
}
@media (max-width:1166px) {
    .imageContainer{
        width: 443px;
    }
}
@media (max-width:1096px) {
    .thirdContainer{
        width: 40%;
    }
}
@media (max-width:913px) {
    .parentContainer{
        align-items: flex-end;
        padding-bottom: 100px;
        height: 1000px;
    }
    .thirdContainer{
        width: 100%;
    }
    .imageContainer{
        width: 452px;
        top: 50%;
        left: 50%;
        transform: translate(-48%, -73%);
    }
}
@media (max-width:500px) {
    .thirdContainer{
        width: 100%;
    }
    .imageContainer{
        width: 400px;
    }
}
@media (min-width: 2000px) {
    .secondContainer{
      max-width: 1200px;
      width: 64%;
  
    }
    .imageContainer{
        left: 303px;
    }
  }
  @media (min-width: 2000px) {
    .imageContainer{
        left: 503px;
    }
  }
  .customFileUpload {
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #4CAF50; // You can change this to your desired color.
    color: white;
    border-radius: 4px;
    margin-bottom: 6px;
    &:hover {
        // styles when the icon is hovered (e.g., scale, color change, etc.)
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
        background-color: #39a23d;
        border-color: transparent;
      }
}

.hiddenFileInput {
    display: none; // This hides the default file input.
}

.fileNameDisplay {
    margin-left: 10px;
    vertical-align: middle; // Aligns the text vertically with the button.
}
.formAlignment{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 200;

    span {
        padding: 20px;
        border-radius: 10px;
    }

    .popupContent {
        position: relative;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        width: auto;  /* Adjust as necessary */
        max-width: 90%; /* Prevents the popup content from being too wide on large screens */
    }
    
    .closeButton {
        position: absolute;
        top: -10px;
        right: -10px;
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #333;  /* Add this to give a background to the close icon */
        border-radius: 50%;  /* Makes it circular */
        width: 30px;  /* Adjust as necessary */
        height: 30px;  /* Adjust as necessary */
        display: flex;  /* Centers the icon */
        align-items: center;  /* Centers the icon */
        justify-content: center;  /* Centers the icon */
    }
    
}



.hiddenFileInput {
    display: none;
}
