.fade {
    opacity: 0;
    transform: translateY(50px); // Initial move downward for the effect.
    transition: opacity 0.6s, transform 0.6s;
  }
  
  .is-visible {
    opacity: 1;
    transform: translateY(0);
  }
  