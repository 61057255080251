
.parentContainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1000px;
    overflow: visible;

}
.secondContainer{
    width: 60%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.videoContainer{
    position: absolute;
    width: 110vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.textBody{
    color: #FFF;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 27.55px */
}
.title{
    color: #FFF;
    text-align: center;
    font-family: Oxygen;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.72px;
    margin-bottom: 4%;
}
@media (max-width:913px) {
.secondContainer{
    width: 86%;
}
.videoContainer{
    width: 1600px;
}
}
@media (min-width: 2000px) {
    .secondContainer{
      max-width: 1200px;
      width: 40%;
  
    }
  }