.parentContainer {
    position: relative;
    width: 100%;
    height: 400px;
  
    .buttonContainer {
      position: absolute;
      top: 10px;  // Adjust as needed
      right: 10px; // Adjust as needed
  
      .directionButton {
        background-color: rgba(0, 0, 0, 0.7); // semi-transparent black
        color: white;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
