.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 800px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 995;


  // background-color: #f0f0f0;
}
.parentContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000061b5;
  z-index: 1; // Assuming video has no z-index. If it does, set this value accordingly.
  background-blend-mode: soft-light, normal;
}
.coloredTitle {
  background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.coloredTitle,
.blackTitle {
  font-family: Oxygen;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
}
.blackTitle {
  color: white;
}
.text {
  color: #fff;
  text-align: center;
  font-family: Oxygen;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 22.8px */
  max-width: 252px;
}
.storyElement {
  z-index: 100000;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.backgroundVideo {
  position: absolute;
  object-fit: cover;

  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
.secondContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 130px;
    z-index: 998;
}
.parentCircleContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10%;
  justify-content: space-evenly;
}

.storyCircle {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1000;

  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: -9px;
    width: 107%;
    height: 107%;
    border-radius: 50%;
    background-image: url("../../../assets/caseStudies/Ellipse 25.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;
  }
}

.storyContainer {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  padding: 75px 0px;
  z-index: 3000;
}

.progressBarContainer {
  position: relative;
  top: 5%;
  left: 5%;
  width: 90%;
  display: flex;
  gap: 4px;
}

.singleProgressBar {
  flex: 1;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.progress {
  height: 100%;
  width: 100%;
  background-color: #fff;
  animation: progressAnimation 10s forwards;
  visibility: hidden;
}

.activeProgress {
  visibility: visible;
}

.caption {
  position: relative;
  display: flex;
  top: 15%;
  left: 5%;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  gap: 2rem;
}
.storyCaption {
  border-left: 3px solid white;
  padding-left: 2rem;
  width: 70%;

}

.storyImage {
  border-radius: 0.6rem;
  width: 350px;
  height: 800px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
}
.storyImage::before {
  content: ""; // Necessary for the pseudo-element to show
  position: absolute; // Absolutely position within .storyImage
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; // Stretch pseudo-element to cover entire .storyImage

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.672), rgba(0, 0, 0, 0.238)); 
  // Adjust the rgba values as needed. Here, I'm starting with a half-transparent black at the top, fading to fully transparent black at the bottom.
}

@keyframes progressAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.storyImage {
  position: relative;
}

.leftArea,
.rightArea {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 2;
}

.leftArea {
  left: 0;
}

.rightArea {
  right: 0;
}

// ... the rest of your SCSS remains unchanged.
@media (max-width:1202px) {


  .parentCircleContainer{
    overflow: auto;
    width: 100vw;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-left: 5%;

    scrollbar-width: none;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
  }
  }
}
@media (max-width:500px) {
  .storyImage{
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  }
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;  // adjust as needed
  color: white;
  cursor: pointer;
  z-index: 3;

  &:hover {
    opacity: 0.8;
  }
}

.noScroll {
  overflow: hidden !important;
  height: 100vh;
  position: fixed;
  width: 100%;
}
@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}