.parentContainer{
    height: 100%;
    padding: 20% 0;

    background-image: url('../../../assets/culture//section4/vCultureSec4LeftBg.svg'), url('../../../assets/culture//section4/DotsCultureSec4RightBg.svg');
    background-position: -12vw center, 44vw center;
    background-repeat: no-repeat, no-repeat;
    background-size: 900px, 1100px;
    display: flex;
    align-items: center;
    justify-content: center;

    
    display: flex;
    align-items: center;
    justify-content: center;
}
.secondContainer{
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

}
.left{
    width: 30%;
}
.right{
    width: 80vw;
}
.coloredTitle {
    background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .coloredTitle,
  .blackTitle {
    font-family: Oxygen;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
  }
  .blackTitle {
    color: black;
  }
  .titleGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border-radius: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .imageContainer{
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

  .textContainer{
    width: 100%;
    background-color: #052247;
    padding: 18px;
  }
  .nameText, .roleText, .reviewText{
    color: white;
    text-align: left;
  }
  .nameText{
    color: #F9F4F5;
    font-family: Oxygen;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 22.8px */
    letter-spacing: 0.029px;
    padding-bottom: 1%;
  }
  .roleText{
    color: #F9F4F5;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.024px;
    padding-bottom: 6%;
  }
  .reviewText{
    color: #F9F4F5;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 145%; /* 21.75px */
    letter-spacing: 0.075px;
  }
  .right {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: auto;
    gap: 20px;
    flex-grow: 1;  
    width: 70vw;
  
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
.right::-webkit-scrollbar {
    display: none; /* For Chrome and Safari */
}

.arrowLeft, .arrowRight {
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    position: absolute;   // set the arrows to be absolutely positioned
  }
  
  // Adjust the arrow positions here if necessary. For example:
  .arrowLeft {
    left: 5%;
    top: 50%;              // center the arrows vertically
    transform: translateY(-50%);
    color: white;
  }
  
  .arrowRight {
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    color: white;
  }
.rightWrapper {
    display: flex;
    align-items: center; // vertically align the arrows
    position: relative;  // set a reference for absolute positioning
    width: 75%;         // set your desired width for the wrapper
  }
  // ... (your existing styles)
.arrowLeft, .arrowRight {
  background-color: #00000097;  // Example light gray background
  padding: 10px;              // Equal padding on all sides
  border-radius: 10px;
  display: inline-flex;       // To center the FontAwesome icon
  align-items: center;        // Vertical centering
  justify-content: center;    // Horizontal centering
  transition: all 0.2s ease;  // Optional: smooth transition on hover

  &:hover {
      cursor: pointer;
      background-color: #000000; // Example: a slightly darker gray on hover
  }
  }
  .videoContainer {
    background-color: black;
    position: relative;
    width: 100%;
    height: 300px; // This value is based on your existing styles and design
    overflow: hidden;
  }
  
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    z-index: 10;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute; 
    top: 0;
    left: 0;
    z-index: 0;  // Ensure the video stays below the play button
  }
  .videoContainer {
    position: relative; // make it a reference point for absolutely positioned children
}

.videoSlider {
    position: absolute;
    bottom: 10px; // adjust this to change the gap from the bottom
    left: 50%;
    transform: translateX(-50%); // this will center the slider
    width: 80%; // or adjust as needed
    z-index: 10;
}

.playButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); // centers the play button horizontally
    z-index: 10; 
    // rest of your play button styles
}

.muteButton {
    position: absolute;
    top: 10px; 
    right: 10px;
    cursor: pointer;
    color: white;
    z-index: 2; // This ensures the button is clickable above the video
}
@media (max-width:913px) {
  .secondContainer{
    width: 86%;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
  .left{
    width: 100%;
    text-align: center;
  }
  .rightWrapper{
    width: 100%;
  }
  .titleGroup{
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width:1252px) {
  .right{
    width: 50vw;
  }
}
/* Modal overlay styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  
  display: flex; 
  align-items: center; 
  justify-content: center; 
  
  background-color: rgba(0, 0, 0, 0.7);
}

/* Modal content container styles */
.modalContent {
  /* Positioning and size */
  width: 100vw; 
  height: 100vh;
  
  /* Box styles */
  background-color: #ffffff;
  overflow: hidden;
  
  /* Flexbox for centering content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Needed for the close button positioning */
}

/* Video element styles */
.modalContent video {
  width: 100%; 
  height: 100%; 
  object-fit: contain; /* This will ensure the video covers the entire viewport */
}

/* Close button styles for the modal */
.modal button {
  /* Positioning */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
  
  /* Box styles */
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  
  /* Visual styles */
  background-color: #333;
  color: #fff;
  cursor: pointer;
  
  /* Hover effect */
  &:hover {
    background-color: #555;
  }
}
@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}