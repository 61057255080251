html,body {
  scroll-padding-top:80px;
  scroll-margin-top:80px;
}
@media only screen and (max-width: 600px) {
  html,body{
    scroll-padding-top:60px;
    scroll-margin-top:60px;
  }
}
.App {
  text-align: center;
  /* padding-top: 100px; */
  background-color: white;
}

.zIndex {
  z-index: 999;
}

.swiper-slide {
  height: 60vh !important;
}

