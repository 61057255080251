.parentContainer {
  background-image: url("../../../assets/knowGood/knowGoodSec4Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 8% 0;
  gap: 60px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.secondContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;

}
.key {
    background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
.key,
.pageTitle {
  font-family: Oxygen;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
}
.pageTitle{
    color: black;
}

.profileImage {
  width: 230px;
  position: relative;
  padding-bottom: 1rem;
}
.ImageContainer{
    position: relative;
    height: auto;
}
.card{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: auto;
}
.LinkIcon{
    width: 20px;
    margin-bottom: 1rem;

}
.cardBack{
  display: flex;
  flex-direction: column;
  img{
    margin-top: 0.5rem;
  }
}
.nameText{
    color: #071013;
text-align: center;
font-family: Source Sans Pro;
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 145%; /* 27.55px */
letter-spacing: 0.095px;
}
.descText{
    color: #071013;
text-align: center;
font-family: Oxygen;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 23.2px */
letter-spacing: 0.04px;
width: 70%;
}
.roleText{
    color: #071013;
text-align: center;
font-family: Source Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 23.2px */
letter-spacing: 0.08px;
margin-bottom: 1rem;
}
@media (max-width:913px) {
  .secondContainer{
    width: 86%;
    flex-direction: column;
    gap: 50px;
  }
  .key,
.pageTitle {
  font-size: 2.4rem;
}
  
}

.card {
  perspective: 1000px; 
  width: 250px; 
  overflow: hidden; 
  // border: 1px solid black;
  // background-color: white;
  padding: 26px 0;
  &:hover .cardInner {
    // transform: rotateY(180deg); flip 
  }
}

.cardInner {
  transform-style: preserve-3d;
  transition: transform 0.6s;
  width: 100%;
  height: 100%;
  position: relative;
}

.cardFront, .cardBack {
  position: relative;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cardFront{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardBack {
  transform: rotateY(180deg);
  background: transparent; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0 20px;
}
.descText{
  display: -webkit-box;
  -webkit-line-clamp: 3; /* limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}