.homeSection {
  flex-direction: column;
  // height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  // padding-top: 80px;
  // padding-bottom: 180px;
  overflow: hidden;
}
.secondContainer {
  height: auto;
}
.paneContainer {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ImageContainer {
  width: 100%;
}
.leftPane {
  height: 80%;
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rightPane {
  float: right;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // padding-bottom: 100px;
  // height: 600px;
  gap: 16px;
  // margin-top: 40px;
}

.successText {
  background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.customBullet {
  list-style-image: url("../../../assets/home/bulletPoint.svg");
  // padding-left: 1rem;
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
}

.title {
  color: #071013;
  font-family: Oxygen;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.body {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
}
.pageHeader {
  text-align: center;
  font-family: Oxygen;
  font-size: 3rem;
  font-style: normal;
  // padding-bottom: 4%;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.72px;
}
.CultureIcon {
  top: 153px;
  right: 67%;
}
.GameIcon {
  top: 297px;
  right: 51%;
}
.ResourceIcon {
  top: 418px;
  right: 61%;
}
.MultifunctionalIcon {
  top: 583px;
  right: 52%;
}
.CultureIcon,
.GameIcon,
.ResourceIcon,
.MultifunctionalIcon {
  display: flex;
  align-items: center;
  position: absolute;
  color: #052247;
  font-family: Oxygen;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.035px;
}
.Icons {
}
.bgImage {
  position: absolute;
  top: 102px;
  right: 60%;
}
.CultureIcon .IconText {
  color: #052247;
}
.GameIcon .IconText {
  color: #9055a2;
}
.ResourceIcon .IconText {
  color: #ff4a1c;
}
.MultifunctionalIcon .IconText {
  color: #3d4446;
}
.mobileMode {
  display: none;
}

/* For the CultureIcon */
@keyframes floatAnimationCulture {
  0%,
  100% {
    transform: translate(5px, 6px);
  }
  25% {
    transform: translate(-5px, -5px);
  }
  50% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(0, 5px);
  }
}

/* For the GameIcon */
@keyframes floatAnimationGame {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 0);
  }
  50% {
    transform: translate(0, -5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
}

/* For the ResourceIcon */
@keyframes floatAnimationResource {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 5px);
  }
  50% {
    transform: translate(-5px, 0);
  }
  75% {
    transform: translate(5px, -5px);
  }
}

/* For the MultifunctionalIcon */
@keyframes floatAnimationMultifunctional {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-6px, -12px);
  }
  50% {
    transform: translate(8px, 3px);
  }
  75% {
    transform: translate(-9px, 5px);
  }
}
.CultureIcon .Icons {
  animation: floatAnimationCulture 4s infinite ease-in-out;
}

.GameIcon .Icons {
  animation: floatAnimationGame 5s infinite ease-in-out;
}

.ResourceIcon .Icons {
  animation: floatAnimationResource 6s infinite ease-in-out;
}

.MultifunctionalIcon .Icons {
  animation: floatAnimationMultifunctional 7s infinite ease-in-out;
}

@media (max-width: 1380px) {
  .CultureIcon {
    right: 68%;
  }
  .GameIcon {
    right: 50%;
  }
  .ResourceIcon {
    right: 62%;
  }
  .MultifunctionalIcon {
    right: 51%;
  }
}
@media (max-width: 1300px) {
  .secondContainer {
  }
}

@media (max-width: 1266px) {
  .CultureIcon {
    right: 69%;
  }
  .GameIcon {
    right: 49%;
  }
  .ResourceIcon {
    right: 62%;
  }
  .MultifunctionalIcon {
    right: 50%;
  }
}
@media (max-width: 1180px) {
  .bgImage {
    right: 59%;
  }
  .CultureIcon {
    right: 68%;
  }
  .GameIcon {
    right: 48%;
  }
  .ResourceIcon {
    right: 62%;
  }
  .MultifunctionalIcon {
    right: 48%;
  }
}
@media (max-width: 1108px) {
  .bgImage {
    right: 57%;
  }
  .CultureIcon {
    right: 67%;
  }
  .GameIcon {
    right: 58%;
    top: 328px;
  }
  .ResourceIcon {
    right: 57%;
    top: 474px;
  }
  .MultifunctionalIcon {
    right: 46%;
    top: 630px;
  }
}

@media (max-width: 913px) {
  .homeSection {
    padding-bottom: 0;
  }
  .secondContainer {
  }
  .leftPane {
    // height: 650px;
  }
  .rightPane {
    width: 100%;
  }
  .paneContainer {
    flex-direction: column;
  }

  .bgImage {
    right: 41%;
  }
  .CultureIcon {
    right: 54%;
  }
  .GameIcon {
    right: 41%;
    top: 328px;
  }
  .ResourceIcon {
    right: 41%;
    top: 474px;
  }
  .MultifunctionalIcon {
    right: 24%;
    top: 630px;
  }
  .rightPane {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .Icons {
    width: 120px;
  }
  .IconText {
    font-size: 1rem;
  }
  .bgImage {
    right: 22%;
    max-width: 200%;
  }

  .CultureIcon {
    right: 50%;
    top: 250px;
  }
  .GameIcon {
    right: 36%;
    top: 380px;
  }
  .ResourceIcon {
    right: 36%;
    top: 490px;
  }
  .MultifunctionalIcon {
    right: 20%;
    top: 610px;
  }
}
@media (max-width: 605px) {
  .pageHeader {
    font-size: 2.4rem;
  }
  .CultureIcon {
    right: 53%;
  }
}
@media (max-width: 580px) {
  .bgImage {
    right: 10%;
  }

  .CultureIcon {
    right: 42%;
    top: 250px;
  }
  .GameIcon {
    right: 26%;
    top: 380px;
  }
  .ResourceIcon {
    right: 26%;
    top: 490px;
  }
  .MultifunctionalIcon {
    right: 6%;
    top: 610px;
  }
}

@media (max-width: 480px) {
  .CultureIcon {
    right: 50%;
  }
  .title {
    font-size: 1.4rem;
  }
}

@media (max-width: 428px) {
  .bgImage {
    right: 0%;
  }
  .Icons {
    width: 100px;
  }
  .IconText {
    font-size: 0.8rem;
  }

  .CultureIcon {
    right: 50%;
    top: 250px;
  }
  .GameIcon {
    right: 30%;
    top: 380px;
  }
  .ResourceIcon {
    right: 32%;
    top: 490px;
  }
  .MultifunctionalIcon {
    right: 16%;
    top: 610px;
  }
}

@media (max-width: 428px) {
  .homeSection .rightPane {
    // height: auto;
    // padding-bottom: 100px;
  }
  .bgImage {
    right: -8%;
  }
  .CultureIcon {
    right: 48%;
    top: 250px;
  }
  .GameIcon {
    right: 28%;
    top: 380px;
  }
  .ResourceIcon {
    right: 28%;
    top: 490px;
  }
  .MultifunctionalIcon {
    right: 8%;
    top: 610px;
  }
  .pageHeader {
    font-size: 2rem;
  }
  .customBullet {
    font-size: 1rem;
  }
}
