.parentContainer {
  background-image: url("../../../assets/knowGood/knowGoodSec3Bg.webp");
  background-size: cover;
  background-position: center;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
.secondContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.circleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.circle {
  width: 200px; /* width of the circle */
  height: 200px;
  background: rgba(14, 5, 48, 0.72);
  background-blend-mode: multiply;
    border-radius: 50%; /* makes it a circle shape */
  border: 1px solid #e74c3c; /* border (or outline) around the circle */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
// .RowCircles:nth-child(1) > .circle:first-child {
//     background: linear-gradient(180deg, #784787 0%, #D43E17 100%);
// }

.RowCircles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.circleInsideArrow {
  width: 40px;
}
.text {
  width: 70%;
  color: white;
  z-index: 2;

}

.tabButton {
  display: flex;
  flex-direction: row;
  align-items: center;
//   background-color: #ff4a1c;
  border-radius: 30px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  transition: background-color 0.3s ease; 
  cursor: pointer;

}
.tabIcon {
  width: 36px;
  margin-right: 10px;
  &:active, &:focus{
    outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  }
}
.activeTab {
    background-color: #ff4a1c; /* Or any color you'd like for the active tab */
  }
  
.tabText {
  color: #f9f4f5;
  font-family: Source Sans Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 27.55px */
  letter-spacing: 0.095px;
  text-transform: capitalize;
}
.tabContainer {
  border-radius: 2000px;
  background: rgba(5, 34, 71, 0.8);
  width: auto;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
}

.key {
  background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.key,
.pageTitle {
  font-family: Oxygen;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
}
.pageTitle{
    color: white;
}

.bodyText{
    color: #FFF;
text-align: center;
font-family: Source Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 23.2px */
letter-spacing: 0.08px;
margin-top: 2rem;
margin-bottom: 4rem;
}

/* Add this at the bottom of your CSS for the media query */

  /* Existing Styles */
.RowCircles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.circle:hover{
  background: linear-gradient(180deg, #784787 0%, #D43E17 100%);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

/* Media Query */
@media (max-width: 992px) {
  .tabText{
    text-align: left;
  }
  .tabContainer{
    flex-direction: column;
    gap: 30px;
    border-radius: 30px;
    align-items: flex-start;
  }
  .circleContainer {
    flex-direction: column;
    align-items: center;
  }

  .RowCircles {
    width: 66%;
    flex-wrap: wrap;  
  }

  .RowCircles > .circle {
    margin-right: 20px; /* margin to account for the arrow */
  }

  .RowCircles > img {
    display: none; /* Hide the arrows between circles */
  }

  /* We can introduce a special arrow after every two circles */
  .RowCircles > .circle:nth-of-type(odd)::after {
    content: '';
    background-image: url('../../../assets/knowGood/icons/CircleRight.svg'); /* Replace this with the path to your arrow image */
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px; /* Adjust as per your arrow width */
    height: 20px; /* Adjust as per your arrow height */
    position: absolute;
    right: -20px; /* Position it to the right of the circle */
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width:1300px) {
  .secondContainer{
    width: 86%;
  }
  
}
@media (max-width:791px) {
  .RowCircles{
    width: 80%;
  }
  
}
@media (max-width:640px) {
  .pageTitle, .key{
    font-size: 2.6rem;
  }
  .RowCircles{
    width: 70%;
  }
  .RowCircles > .circle {
    margin-right: 0; /* margin to account for the arrow */
    margin-bottom: 20px;
  }
  
  .RowCircles > .circle:nth-of-type(odd)::after {
    right: 93px;
    top: 100%;
    transform: rotate(90deg);

  }

}
@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}